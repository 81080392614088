import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import BatteryIcon from "./BatteryIcon";

function DeviceTypeName({ deviceType }) {
    switch(deviceType.toLowerCase()) {
        case "inverter":
            return "Omvormer";
        case "battery":
            return "Batterij";
        case "energymeter":
            return "Energiemeter";
        case "evcharger":
            return "Laadpaal";
        case "relay":
            return "Relais";
        case "gateway":
            return "Gateway";
        default:
            return "Unknown";
    }
}

function DeviceIconInternal({ deviceType, ...props }) {
    switch(deviceType.toLowerCase()) {
        case "inverter":
            return (
                <i className="fa-solid fa-solar-panel fa-fw"/>
            );
        case "battery":
            return (
                <BatteryIcon {...props}/>
            );
        case "energymeter":
            return (
                <i className="fa-solid fa-meter-bolt fa-fw"/>
            );
        case "evcharger":
            return (
                <i className="fa-solid fa-charging-station fa-fw"/>
            );
        case "relay":
            return (
                <i className="fa-solid fa-light-switch fa-fw"/>
            );
        case "gateway":
            return (
                <i className="fa-solid fa-router fa-fw"/>
            );
        default:
            return (
                <i className="fa-solid fa-question fa-fw"/>
            );
    }
}

function DeviceIcon({ deviceType, ...props }) {
    return (
        <OverlayTrigger overlay={
            <Tooltip id={ `device-icon-${deviceType}` }>
                <DeviceTypeName deviceType={ deviceType }/>
            </Tooltip>
        }>
            <div>
                <DeviceIconInternal deviceType={ deviceType } {...props}/>
            </div>
        </OverlayTrigger>
    );
}

export default React.memo(DeviceIcon);
