import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import LiveUpdateIndicator from "../../../components/LiveUpdateIndicator";
import Loading from "../../../components/Loading";
import GridSenseEnergyFlow from "../../../components/GridSenseEnergyFlow";

function DashboardTotalEnergyFlowCard() {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [animate, setAnimate] = useState(false);

    const updateData = useCallback(async () => {
        try {
            const response = await axios.get("/getTotalEnergyFlow");
            if(!response.data.data) {
                setError("Geen data beschikbaar.")
                return;
            }
            setData(response.data.data);
            setError(null);
            setAnimate(true);
            setTimeout(() => setAnimate(false), 3000);
            return response.data.data;
        } catch(error) {
            console.error(error);
            setError("Er is een fout opgetreden. Probeer het later opnieuw.");
        }
    }, []);
    useEffect(() => {
        const setup = async () => {
            await updateData();
        };
        setup();
        const timer = setInterval(setup, [5000]);
        return () => clearTimeout(timer);
    }, [updateData]);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <div className="d-flex flex-row mb-2 justify-content-center">
                    <div className="flex-grow-1">
                        <h5 className="card-title mb-0">Live inzicht</h5>
                    </div>
                    <div className="d-flex align-items-center pt-1">
                        <LiveUpdateIndicator animate={ animate }/>
                    </div>
                </div>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !data ? (
                    <Loading/>
                ) : (
                    <GridSenseEnergyFlow
                        { ...data }
                        unit="kW"
                    />
                )}
            </div>
        </div>
    );
}

export default React.memo(DashboardTotalEnergyFlowCard);
