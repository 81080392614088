import React from "react";
import {
    Button
} from "react-bootstrap";

import DateFormatter from "../../../../../components/formatters/DateFormatter";
import DeviceIcon from "../../../../../components/DeviceIcon";
import StatusDot from "../../../../../components/StatusDot";
import DeviceCardRow from "./components/DeviceCardRow";

export function InstallationRelayCard({
    relay,
    onClickAddDevice,
    onClickSettings,
    disabled,
}) {
    return (
        <div className="card mb-3">
            <div className="card-body">
                <div className="d-flex flex-row">
                    <div className="flex-grow-1">
                        <h5 className="card-title">
                            { relay.manufacturer }{" "}
                            <br className="d-md-none"/>
                            { relay.model }
                            <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                                { relay.serialNumber }
                            </div>
                        </h5>
                    </div>
                    <div className="ml-3">
                        <DeviceIcon deviceType="relay"/>
                    </div>
                </div>
                <DeviceCardRow title="Aantal relais">
                    { relay.relayCount }
                </DeviceCardRow>
                <DeviceCardRow title="Status">
                    <div
                        className={"d-flex flex-row flex-wrap" + (onClickSettings ? " pointer-cursor" : "")}
                        style={{ gap: "0.25rem" }}
                        onClick={ onClickSettings ? () => onClickSettings(relay) : undefined }
                    >
                        { relay.currentState.map((state, index) => (
                            <StatusDot
                                key={ index }
                                name={ `Relais ${index + 1} - ${state ? "Aan" : "Uit"}` }
                                color={ state ? "#198754" : "#dc3545" }
                            >
                                { index + 1 }
                            </StatusDot>
                        ))}
                    </div>
                </DeviceCardRow>
                <DeviceCardRow title="Laatst gezien">
                    <DateFormatter date={relay.lastSeenDate}/>
                </DeviceCardRow>
                {onClickAddDevice && relay.installationId === null && (
                    <div className="d-flex justify-content-end mt-2">
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={ () => onClickAddDevice("relay", relay.id) }
                            disabled={ disabled }
                        >
                            <i className="fa-solid fa-plus mr-2"/>
                            Relais toevoegen
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

function InstallationRelayCards({
    relays,
    onClickAddDevice,
    disabled,
}) {
    return relays.map((relay) => (
        <InstallationRelayCard
            key={relay.id}
            relay={relay}
            onClickAddDevice={onClickAddDevice}
            disabled={disabled}
        />
    ));
}

export default React.memo(InstallationRelayCards);
