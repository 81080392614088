import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";

import Loading from "../../../../components/Loading";
import InstallationInverterCards from "../components/device-cards/InstallationInverterCards";
import InstallationBatteryCards from "../components/device-cards/InstallationBatteryCards";
import InstallationEnergyMeterCards from "../components/device-cards/InstallationEnergyMeterCards";
import InstallationEVChargerCards from "../components/device-cards/InstallationEVChargerCards";
import InstallationRelayCards from "../components/device-cards/InstallationRelayCards";

function InstallationAddDeviceModal({ show, handleClose, installation }) {
    const [inverters, setInverters] = useState(null);
    const [batteries, setBatteries] = useState(null);
    const [energyMeters, setEnergyMeters] = useState(null);
    const [evChargers, setEVChargers] = useState(null);
    const [relays, setRelays] = useState(null);

    const [error, setError] = useState(null);

    const [addingDevice, setAddingDevice] = useState(false);
    const [addError, setAddError] = useState(null);

    useEffect(() => {
        if(!show) {
            return;
        }
        setAddingDevice(false);
        setAddError(null);
    }, [show]);

    const refreshDevices = useCallback(async () => {
        setInverters(null);
        setBatteries(null);
        setEnergyMeters(null);
        setEVChargers(null);
        setError(null);
        try {
            const response = await axios.post("/getDevicesForInstallationGateways", { installationId: installation.id });
            setInverters(response.data.inverters);
            setBatteries(response.data.batteries);
            setEnergyMeters(response.data.energyMeters);
            setEVChargers(response.data.evChargers);
            setRelays(response.data.relays);
        } catch (error) {
            console.error(error);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, [installation]);
    useEffect(() => {
        if(!show) {
            return;
        }
        refreshDevices();
    }, [show, refreshDevices]);

    const addDevice = useCallback(async (deviceType, deviceId) => {
        setAddError(null);
        setAddingDevice(true);
        try {
            await axios.post("/addDeviceToInstallation", { installationId: installation.id, deviceType, deviceId });
            await refreshDevices();
        } catch(error) {
            console.error(error);
            setAddError("Er is iets fout gegaan. Probeer het later opnieuw.");
        } finally {
            setAddingDevice(false);
        }
    }, [refreshDevices, installation]);

    return (
        <Modal show={ show } onHide={ handleClose } size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Apparaat toevoegen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !inverters || !batteries || !energyMeters ? (
                    <Loading/>
                ) : (
                    inverters.length === 0
                    && batteries.length === 0
                    && energyMeters.length === 0
                    && evChargers.length === 0
                    && relays.length === 0
                ) ? (
                    <div className="text-center">
                        <h1><i className="fa-solid fa-solar-panel"/></h1>
                        <h4>Geen apparaten gevonden</h4>
                        <p>Er zijn geen apparaten gevonden bij gateways van deze installatie.</p>
                        <Button variant="primary" onClick={ refreshDevices }>
                            <i className="fa-solid fa-sync mr-2"/>
                            Vernieuwen
                        </Button>
                    </div>
                ) : (
                    <React.Fragment>
                        { addError && (
                            <Alert variant="danger">{ addError }</Alert>
                        )}
                        <div className="d-flex justify-content-end mb-2">
                            <Button variant="primary" onClick={ refreshDevices } disabled={ addingDevice }>
                                <i className="fa-solid fa-sync mr-2"/>
                                Vernieuwen
                            </Button>
                        </div>
                        { inverters && inverters.length > 0 && (
                            <InstallationInverterCards
                                inverters={ inverters }
                                onClickAddDevice={ addDevice }
                                disabled={ addingDevice }
                            />
                        )}
                        { batteries && batteries.length > 0 && (
                            <InstallationBatteryCards
                                batteries={ batteries }
                                onClickAddDevice={ addDevice }
                                disabled={ addingDevice }
                            />
                        )}
                        { energyMeters && energyMeters.length > 0 && (
                            <InstallationEnergyMeterCards
                                energyMeters={ energyMeters }
                                onClickAddDevice={ addDevice }
                                disabled={ addingDevice }
                            />
                        )}
                        { evChargers && evChargers.length > 0 && (
                            <InstallationEVChargerCards
                                evChargers={ evChargers }
                                onClickAddDevice={ addDevice }
                                disabled={ addingDevice }
                            />
                        )}
                        { relays && relays.length > 0 && (
                            <InstallationRelayCards
                                relays={ relays }
                                onClickAddDevice={ addDevice }
                                disabled={ addingDevice }
                            />
                        )}
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ addingDevice }>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(InstallationAddDeviceModal);
