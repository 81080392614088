import React from "react";

function DeviceCardRow({ title, children }) {
    return (
        <div className="row">
            <div className="col-5 text-right">
                <b>{ title }</b>
            </div>
            <div className="col">
                { children }
            </div>
        </div>
    )
}

export default React.memo(DeviceCardRow);
