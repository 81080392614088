import React, {
    useEffect,
    useRef,
    useState
} from "react";
import {
    ErrorBoundary
} from "@sentry/react";
import {
    Alert
} from "react-bootstrap";
import {
    EnergyFlow
} from "@gridsense/react-energy-flow";
import BatteryIcon from "./BatteryIcon";

function GridSenseEnergyFlow({ ...props }) {
    const ref = useRef(null);
    const [width, setWidth] = useState(0);
    const [scale, setScale] = useState(1);

    useEffect(() => {
        if(!ref.current) {
            return;
        }
        const currentRef = ref.current;
        const observer = new ResizeObserver(() => {
            setWidth(currentRef.offsetWidth);
        });
        observer.observe(currentRef);
        return () => observer.unobserve(currentRef);
    }, []);

    useEffect(() => {
        setScale(width > 270 ? 1 : 0.8);
    }, [width]);

    return (
        <div
            ref={ ref }
            className="d-flex justify-content-center w-100"
        >
            <div>
                <ErrorBoundary fallback={
                    <Alert variant="danger">Er is een fout opgetreden.</Alert>
                }>
                    <EnergyFlow
                        { ...props }
                        scale={ scale }
                        batteryIcon={
                            <BatteryIcon soe={props.batterySoe}/>
                        }
                    />
                </ErrorBoundary>
            </div>
        </div>
    );
}

export default React.memo(GridSenseEnergyFlow);
