import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    useLocalStorage
} from "@zandor300/backoffice-framework";

import LiveUpdateIndicator from "../../../../../../components/LiveUpdateIndicator";
import Loading from "../../../../../../components/Loading";
import InstallationEnergyFlowLayout from "./InstallationEnergyFlowLayout";
import InstallationEnergyListLayout from "./InstallationEnergyListLayout";
import GridSenseEnergyFlow from "../../../../../../components/GridSenseEnergyFlow";
import DateFormatter from "../../../../../../components/formatters/DateFormatter";
import TagPill from "../../../../../../components/TagPill";

function InstallationEnergyFlowAutoCard({ installation }) {
    const [layout, setLayout] = useLocalStorage("installationEnergyFlowAutoCardLayout", "flow");
    const [layoutForced, setLayoutForced] = useState(null);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [noData, setNoData] = useState(false);
    const [animate, setAnimate] = useState(false);

    const updateData = useCallback(async () => {
        if(!installation) {
            return;
        }
        try {
            const response = await axios.post("/getInstallationCurrentEnergyFlow", { installationId: installation.id });
            if(!response.data.data) {
                setNoData(true);
                setError("Geen data beschikbaar.")
                return;
            }
            setData(response.data.data);
            setError(null);
            setNoData(false);
            setAnimate(true);
            setTimeout(() => setAnimate(false), 3000);
            return response.data.data;
        } catch(error) {
            console.error(error);
            setError("Er is een fout opgetreden. Probeer het later opnieuw.");
        }
    }, [installation]);
    useEffect(() => {
        const setup = async () => {
            await updateData();
        };
        setup();
        const timer = setInterval(setup, 5000);
        return () => clearTimeout(timer);
    }, [updateData, installation]);

    const selectedLayout = useMemo(() => {
        return layoutForced ?? layout;
    }, [layoutForced, layout]);
    const showLayoutToggle = useMemo(() => {
        if(!data) {
            return false;
        }
        return layoutForced === null;
    }, [layoutForced, data]);
    const toggleLayout = useCallback(() => {
        const options = ["flow", "list", "flow-old"];
        setLayout((prevLayout) => {
            const currentLayoutIndex = options.indexOf(prevLayout);
            const nextLayoutIndex = (currentLayoutIndex + 1) % options.length;
            return options[nextLayoutIndex];
        });
        setLayoutForced(null);
    }, [setLayout]);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <div className="d-flex flex-row mb-2 justify-content-center">
                    <div className="flex-grow-1">
                        <h5 className="card-title mb-0">
                            Live inzicht
                            { (data && selectedLayout === "flow") && (
                                <small>
                                    <TagPill variant="primary" className="ml-2">
                                        beta layout
                                    </TagPill>
                                </small>
                            )}
                        </h5>
                    </div>
                    <div className="d-flex align-items-center pt-1">
                        { showLayoutToggle && (
                            <OverlayTrigger overlay={
                                <Tooltip id="layout-switch">
                                    Layout wisselen
                                </Tooltip>
                            }>
                                <Button variant="link" size="sm" onClick={ toggleLayout } className="py-0">
                                    { selectedLayout === "flow" ? (
                                        <i className="fa-solid fa-list fa-fw"/>
                                    ) : (
                                        <i className="fa-solid fa-arrows-to-dot fa-fw"/>
                                    )}
                                </Button>
                            </OverlayTrigger>
                        )}
                        <LiveUpdateIndicator animate={ animate }/>
                    </div>
                </div>
                { noData ? (
                    <div className="text-center text-muted mt-3">
                        <i
                            className="fa-solid fa-circle-bolt"
                            style={{ fontSize: "2rem" }}
                        />
                        <p
                            className="mb-0"
                            style={{ fontSize: "1.2rem" }}
                        >
                            Geen data beschikbaar
                        </p>
                    </div>
                ) : error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !data ? (
                    <Loading/>
                ) : selectedLayout === "flow-old" ? (
                    <InstallationEnergyFlowLayout
                        data={ data }
                    />
                ) : selectedLayout === "flow" ? (
                    <GridSenseEnergyFlow
                        { ...data }
                    />
                ) : selectedLayout === "list" && (
                    <InstallationEnergyListLayout
                        data={ data }
                    />
                )}
                { data && data.lastUpdated !== null && (
                    <div className="text-muted mt-2" style={{ fontSize: "0.75rem" }}>
                        Laatst bijgewerkt:{" "}
                        <DateFormatter date={ data.lastUpdated }/>
                    </div>
                )}
            </div>
        </div>
    );
}

export default React.memo(InstallationEnergyFlowAutoCard);
