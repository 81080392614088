import React, {
    useMemo
} from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function StatusDot({ name, color, children, className, style, ...restProps }, ref) {
    const additionalStyle = style ?? {};
    const classNames = useMemo(() => {
        let classNames = ["d-inline-block rounded-circle text-white"];
        if(className) {
            classNames.push(className);
        }
        return classNames.join(" ");
    }, [className]);

    if(!name || !color) {
        return null;
    }

    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={ `tooltip-${name}` }>
                    { name }
                </Tooltip>
            }
        >
            <div
                ref={ ref }
                className={ classNames }
                style={{
                    width: children ? "1.5rem" : "1rem",
                    height: children ? "1.5rem" : "1rem",
                    marginTop: children ? undefined : "0.25rem",
                    marginBottom: children ? undefined : "0.25rem",
                    fontSize: "0.75rem",
                    paddingTop: "0.2rem",
                    paddingBottom: "0.25rem",
                    textAlign: "center",
                    backgroundColor: color,
                    ...additionalStyle,
                }}
                {...restProps}
            >
                { children }
            </div>
        </OverlayTrigger>
    );
}

export default React.memo(React.forwardRef(StatusDot));
