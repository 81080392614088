import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import moment from "moment";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";
import InstallationTypeIcon from "../../components/InstallationTypeIcon";
import usePermissions from "../../hooks/usePermissions";
import DateFormatter from "../../components/formatters/DateFormatter";
import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";

function Installations() {
    const authentication = useContext(AuthenticatedUserContext);
    const permissions = usePermissions();

    const [installations, setInstallations] = useState(null);
    const [error, setError] = useState(null);

    const showCompanyName = useMemo(() => {
        if(permissions.globalAdmin) {
            return true;
        }
        return authentication.user?.companies?.length > 2;
    }, [authentication, permissions.globalAdmin]);

    useEffect(() => {
        setInstallations(null);
        setError(null);
        axios.get("/getInstallations")
            .then((response) => {
                if(response.data.valid) {
                    setInstallations(response.data.installations);
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")")
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.")
            })
    }, []);
    return (
        <React.Fragment>
            <Helmet title="Installaties"/>
            <Title
                preTitle="Overzicht"
                noBottom={ !!installations }
                preChildren={
                    permissions.manageInstallations && (
                        <div className="float-right pt-2">
                            <OverlayTrigger overlay={
                                <Tooltip id={`tooltip-add-installation`}>
                                    Installatie aanmaken
                                </Tooltip>
                            }>
                                <Link to="/installations/add" className="btn btn-primary">
                                    <i className="fa-solid fa-plus fa-fw"/>
                                </Link>
                            </OverlayTrigger>
                        </div>
                    )
                }
            >
                Installaties
            </Title>
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !installations ? (
                <Loading/>
            ) : (
                <Table hover>
                    <thead>
                    <tr className="tr-sticky">
                        <th className="text-center">#</th>
                        <th className="d-none d-md-table-cell text-center">Type</th>
                        <th className="d-none d-md-table-cell">Naam</th>
                        <th className="d-none d-md-table-cell">Adres</th>
                        <th className="d-none d-lg-table-cell text-right">Laatst bijgewerkt</th>
                        <th className="d-none d-xl-table-cell text-center">Online apparaten</th>
                        { showCompanyName && (
                            <th className="d-none d-xl-table-cell text-left">Bedrijf</th>
                        )}
                        <th className="d-md-none">Installatie</th>
                    </tr>
                    </thead>
                    <tbody>
                    { installations.map((installation) => {
                        const url = `/installation/${installation.id}`;
                        const offline = installation.lastUpdateDate && moment(installation.lastUpdateDate) < moment().subtract(30, "minutes");
                        return (
                            <tr key={ installation.id } className="table-clickable-row">
                                <td className="align-middle text-center">
                                    <Link to={ url }>
                                        { installation.id }
                                    </Link>
                                </td>
                                <td className="d-none d-md-table-cell align-middle text-center">
                                    <Link to={ url }>
                                        <InstallationTypeIcon
                                            type={ installation.type }
                                            className="text-muted"
                                        />
                                    </Link>
                                </td>
                                <td className="d-none d-md-table-cell align-middle">
                                    <Link to={ url }>
                                        <b>{ installation.name }</b>
                                    </Link>
                                </td>
                                <td className="d-none d-md-table-cell align-middle">
                                    <Link to={ url }>
                                        { installation.address }, { installation.city }
                                    </Link>
                                </td>
                                <td className="d-none d-lg-table-cell align-middle text-right">
                                    <Link to={ url } className={ offline ? "text-danger" : "" }>
                                        { installation.lastUpdateDate ? (
                                            <DateFormatter date={ installation.lastUpdateDate }/>
                                        ) : (<i className="text-muted">Nooit</i>)}
                                    </Link>
                                </td>
                                <td className="d-none d-xl-table-cell align-middle text-center">
                                    <Link to={ url } className={ offline ? "text-danger" : "" }>
                                        { installation.devicesTotal > 0 ? (
                                            <span className={ installation.devicesTotal > installation.devicesOnline ? "text-danger" : "text-success" }>
                                                { installation.devicesOnline } / { installation.devicesTotal }
                                            </span>
                                        ) : (<i className="text-muted">Geen apparaten</i>)}
                                    </Link>
                                </td>
                                { showCompanyName && (
                                    <td className="d-none d-xl-table-cell align-middle text-left">
                                        <Link to={ url }>
                                            { installation.company?.name }
                                        </Link>
                                    </td>
                                )}
                                <td className="d-md-none align-middle">
                                    <Link to={ url }>
                                        <InstallationTypeIcon
                                            type={ installation.type }
                                            className="text-muted mr-2"
                                        />
                                        <b>
                                            { installation.name }
                                        </b>
                                        <br/>
                                        <span className="text-muted">
                                            { installation.address }, { installation.city }
                                            <br/>
                                            <small className={ offline ? "text-danger" : "" }>
                                                Bijgewerkt:{" "}
                                                { installation.lastUpdateDate ? (
                                                    <DateFormatter date={ installation.lastUpdateDate }/>
                                                ) : ("Nooit") }
                                            </small>
                                        </span>
                                    </Link>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            )}
        </React.Fragment>
    )
}

export default React.memo(Installations);
