import React, {
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import axios from "axios";

function RelayIndexSettingsModal({ show, handleClose, relay, index, onSaved }) {
    const [settingsState, setSettingsState] = useState(null);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const relayRef = useRef();
    const relayIndexRef = useRef();

    const updateSettingsState = useCallback(() => {
        setSettingsState(relay.settings[index]);
    }, [relay, index]);
    const onShow = useCallback(() => {
        updateSettingsState();
        setError(null);
        setSaving(false);
    }, [updateSettingsState]);

    useEffect(() => {
        if(!show || !relay || !index) {
            return;
        }
        if(relayRef.current === relay.id && relayIndexRef.current === index) {
            return;
        }
        updateSettingsState();
        relayRef.current = relay.id;
        relayIndexRef.current = index;
    }, [relay, index, show, updateSettingsState]);

    const handleSave = useCallback(async () => {
        setError(null);
        setSaving(true);
        try {
            await axios.post("/setRelaySettings", {
                relayId: relay.id,
                relayIndex: index,
                settings: JSON.stringify(settingsState)
            });
            onSaved?.();
            handleClose();
        } catch(requestError) {
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, [settingsState, relay, index, onSaved, handleClose]);

    const onChange = useCallback((key, value) => {
        setSettingsState((prevSettings) => {
            const newSettings = { ...prevSettings };
            newSettings[key] = value;
            return newSettings;
        });
    }, []);

    return (
        <Modal
            show={ show }
            onShow={ onShow }
            onHide={ handleClose }
            className="modal-level2"
            backdropClassName="modal-backdrop-level2"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Relais instellingen voor relais { index + 1 }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <div className="form-group">
                    <label htmlFor="name">
                        Naam
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={settingsState?.name ?? ""}
                        onChange={(event) => onChange("name", event.target.value)}
                        disabled={saving}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="nominalPower">
                        Nominaal vermogen
                    </label>
                    <div className="input-group">
                        <input
                            type="number"
                            className="form-control"
                            id="nominalPower"
                            value={settingsState?.nominalPower ?? 0}
                            onChange={(event) => onChange("nominalPower", event.target.value)}
                            disabled={saving}
                        />
                        <div className="input-group-append">
                            <div className="input-group-text">W</div>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="minOnTime">
                        Minimale aan tijd
                    </label>
                    <div className="input-group">
                        <input
                            type="number"
                            className="form-control"
                            id="minOnTime"
                            value={settingsState?.minOnTime ?? 0}
                            onChange={(event) => onChange("minOnTime", event.target.value)}
                            disabled={saving}
                        />
                        <div className="input-group-append">
                            <div className="input-group-text">seconde</div>
                        </div>
                    </div>
                </div>
                <div className="custom-control custom-checkbox mb-2">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="alwaysOnMaxPriceEnable"
                        checked={settingsState?.alwaysOnMaxPrice !== null}
                        onChange={(event) => {
                            onChange("alwaysOnMaxPrice", event.target.checked ? 0 : null);
                        }}
                    />
                    <label className="custom-control-label" htmlFor="alwaysOnMaxPriceEnable">
                        Automatisch inschakelen onder bepaalde prijs
                    </label>
                </div>
                {settingsState?.alwaysOnMaxPrice !== null && (
                    <div className="form-group ml-4">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">&euro;</div>
                            </div>
                            <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                id="minOnTime"
                                value={settingsState?.alwaysOnMaxPrice ?? 0}
                                onChange={(event) => onChange("alwaysOnMaxPrice", event.target.value)}
                                disabled={saving}
                            />
                            <div className="input-group-append">
                                <div className="input-group-text">/ kWh</div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="excessPvEnable"
                        checked={settingsState?.excessPvEnable}
                        onChange={(event) => {
                            onChange("excessPvEnable", event.target.checked);
                        }}
                    />
                    <label className="custom-control-label" htmlFor="excessPvEnable">
                        Automatisch inschakelen bij overtollige zonne-energie
                    </label>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                    disabled={saving}
                >
                    Annuleren
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSave}
                >
                    {saving && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    Opslaan
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(RelayIndexSettingsModal);
